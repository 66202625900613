import { elideTextByWord } from './text';

const maxSnippetLength = 175;

function buildCategoryResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildConceptResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildPersonResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildPublicationResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
    date: document.year,
    published_in: document.published_in,
    authors: document.authors,
  };
}

function buildCourseResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: `${document.short_code} - ${document.name[locale].value}`,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildLectureResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    video: document.external_url[locale],
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildMoocResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildStartupResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document.description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildUnitResult(document, locale) {
  return {
    type: document.type,
    link: document.url,
    title: document.short_code,
    snippet: document.name[locale].value,
    path: document.path,
  };
}

function buildSemanticResults(json, locale) {
  let items = [];

  for (let i = 0; i < json.items.length; i++) {
    let document = json.items[i];
    switch (document.type) {
      case 'category':
        items.push(buildCategoryResult(document, locale));
        break;
      case 'concept':
        items.push(buildConceptResult(document, locale));
        break;
      case 'person':
        items.push(buildPersonResult(document, locale));
        break;
      case 'publication':
        items.push(buildPublicationResult(document, locale));
        break;
      case 'course':
        items.push(buildCourseResult(document, locale));
        break;
      case 'lecture':
        items.push(buildLectureResult(document, locale));
        break;
      case 'mooc':
        items.push(buildMoocResult(document, locale));
        break;
      case 'startup':
        items.push(buildStartupResult(document, locale));
        break;
      case 'unit':
        items.push(buildUnitResult(document, locale));
        break;
    }
  }
  return items;
}

export { buildSemanticResults };
