<template>
  <div>
    <table class="table table-striped result__table tablesaw-stack">
      <tbody>
        <tr v-if="cosec">
          <td colspan="4" class="p-0 text-left border-bottom-0">
            <h3 class="h4 pt-4 border-bottom border-dark">
              {{ $t('results.unit.admin') }}
            </h3>
          </td>
        </tr>

        <tr v-for="(person, i) in cosec" :key="'c' + i">
          <td>
            <a :href="$personLink(person.profile)">
              {{ person.name }}, {{ person.firstname }}
            </a>
          </td>
          <td>{{ person.role }}</td>
          <td class="text-nowrap">
            <span v-for="(office, i) in person.officeList" :key="i">
              <a :href="`http://plan.epfl.ch/?room==${encodeURI(office)}`">
                {{ office }}
              </a>
              <br />
            </span>
          </td>
          <td class="text-nowrap">
            <span v-for="(phone, i) in person.phoneList" :key="i">
              <a :href="`tel:${phone.replace(/ /g, '')}`" class="text-nowrap">
                {{ phone }}
              </a>
              <br />
            </span>
          </td>
        </tr>

        <tr>
          <td colspan="4" class="p-0 text-left border-bottom-0">
            <h3 class="h4 pt-4 border-bottom border-dark">
              {{
                $tc('results.unit.people-count', people.length, {
                  count: people.length,
                })
              }}
            </h3>
          </td>
        </tr>

        <tr v-for="(person, i) in people" :key="i">
          <td>
            <a :href="$personLink(person.profile)"
              >{{ person.name }}, {{ person.firstname }}</a
            >
          </td>
          <td>{{ person.position }}</td>
          <td class="text-nowrap">
            <span v-for="(office, i) in person.officeList" :key="i">
              <a :href="`http://plan.epfl.ch/?room==${encodeURI(office)}`">{{
                office
              }}</a
              ><br />
            </span>
          </td>
          <td class="text-nowrap">
            <span v-for="(phone, i) in person.phoneList" :key="i">
              <a :href="`tel:${phone.replace(/ /g, '')}`" class="text-nowrap">
                {{ phone }}</a
              ><br />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    people: {
      type: Array,
      required: true,
    },
    cosec: Array,
  },
};
</script>
